import React from 'react';
import { Heading, Box, Image, Stack, Center } from '@chakra-ui/react';
import { TItem } from '../types/Item';
import RecieveButton from './RecieveButton';

function ItemCard(props: TItem): JSX.Element {
  return (
    <Stack
      direction="column"
      spacing="30px"
      bgColor="white"
      borderRadius="20px"
      p="30px"
      align="center"
    >
      <Box maxW="md" maxH="md">
        <Image
          boxSize="md"
          borderRadius="20px"
          objectFit="contain"
          src={props.image}
        />
      </Box>
      <Box p={4} maxW="md">
        <Heading as="h1" size="md">
          {props.name}
          {props.isRequested ? '- 受取申請済み' : ''}
        </Heading>
        <Box py={4} overflow="auto" textOverflow="ellipsis">
          {props.description}
        </Box>
        <Center>
          <RecieveButton {...props} />
        </Center>
      </Box>
    </Stack>
  );
}

export default ItemCard;
