import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Theme from './theme/Theme';
import PrivateRoute from './PrivateRoute';
import { WalletProvider } from './context/WalletProvider';
import { FirebaseProvider } from './context/FirebaseProvider';
import Home from './pages/Home';
import Login from './pages/Login';

function App(): JSX.Element {
  return (
    <ChakraProvider theme={Theme}>
      <FirebaseProvider>
        <WalletProvider>
          <BrowserRouter>
            <Switch>
              <PrivateRoute exact path="/" component={Home} />
              <Route exact path="/login" component={Login} />
            </Switch>
          </BrowserRouter>
        </WalletProvider>
      </FirebaseProvider>
    </ChakraProvider>
  );
}

export default App;
