import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { FirebaseContext } from './context/FirebaseProvider';

type PrivateRouteProps = RouteProps & {
  component: React.FC;
};

function PrivateRoute(props: PrivateRouteProps): JSX.Element {
  const { user, isLoading } = useContext(FirebaseContext);
  const { component: Component, ...rest } = props;
  return isLoading ? (
    <></>
  ) : (
    <Route
      render={(p) =>
        user ? <Component {...p} /> : <Redirect to={{ pathname: '/login' }} />
      }
      {...rest}
    />
  );
}

export default PrivateRoute;
