import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Center,
  Stack,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Text,
  Link,
} from '@chakra-ui/react';
import { WalletContext } from '../context/WalletProvider';
import TokenTransfer from './TokenTransfer';

export type FormValues = {
  tokenId: number;
};

function TokenOwnerCheckCard(): JSX.Element {
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [tokenId, setTokenId] = useState<number>(0);
  const [error, setError] = useState<string>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const { getTokenOwner, isConnected, address, getTotalSupply } =
    useContext(WalletContext);

  const onSubmit = handleSubmit(async (values: FormValues) => {
    setError('');
    // Validate TokenId
    const totalSupply = await getTotalSupply();
    if (values.tokenId <= totalSupply) {
      const tokenOwner = await getTokenOwner(values.tokenId);
      setTokenId(values.tokenId);
      if (address === tokenOwner) {
        setIsConfirmed(true);
      } else {
        alert('お客様はこのNFTを所有していません');
      }
    } else {
      setError('有効なIDを入力してください');
    }
  });

  return isConfirmed ? (
    // NFT返送画面へ遷移
    <TokenTransfer tokenId={tokenId} />
  ) : (
    <Center>
      <Stack
        direction="column"
        spacing="10px"
        bgColor="white"
        borderRadius="20px"
        p="30px"
        align="center"
      >
        <Heading size="md" pb={4}>
          2. 交換するNFTの情報を確認して、返送してください
        </Heading>
        <Text color="red.400">
          必ず行使期間をご確認ください。行使期間外の場合対応できない場合があります。
        </Text>
        <Box>
          <Link
            isExternal
            color="blue.400"
            href={`https://opensea.io/${address}`}
          >
            所有しているNFTを見る
          </Link>
        </Box>
        <Box>
          <Link
            isExternal
            color="blue.400"
            href="https://lp.0xdali.io/guide#exchange"
          >
            TokenIDの探し方
          </Link>
        </Box>
        <form onSubmit={onSubmit}>
          <Box p={4}>
            <FormControl id="tokenId" isInvalid={!!errors.tokenId} py={2}>
              <FormLabel>TokenIDを入力してください</FormLabel>
              <Text>{error}</Text>
              <Input
                type="tokenId"
                {...register('tokenId', { required: true })}
              />
              <FormErrorMessage>IDを入力してください</FormErrorMessage>
            </FormControl>
          </Box>
          <Center>
            <Button
              p={4}
              mb={4}
              borderRadius="20px"
              colorScheme="blackButton"
              w="180px"
              type="submit"
              isDisabled={!isConnected}
            >
              確認する
            </Button>
          </Center>
        </form>
      </Stack>
    </Center>
  );
}

export default TokenOwnerCheckCard;
