import { useState, useEffect } from 'react';
import { db } from '../context/FirebaseProvider';
import { TUser } from '../../functions/src/models/User';
import { TItem } from '../types/Item';
import { TAddress } from '../types/Address';

// userのidからprofileとってくる
export function useProfile(email?: string): TUser {
  const [profile, setProfile] = useState<TUser>({} as TUser);

  useEffect(() => {
    const getProfile = async () => {
      const p = await db
        .collection('users')
        .doc(email)
        .get()
        .then((snapshot) => {
          const res = snapshot.data();
          return res as TUser;
        });
      setProfile(p);
    };
    if (email) {
      getProfile();
    }
  }, []);

  return profile;
}

// userのidから所有しているitemをとってくる
export function useItems(email?: string): TItem[] {
  const [items, setItems] = useState<TItem[]>([]);
  const profile = useProfile(email);

  // profile周りもっとうまくかけるかも
  useEffect(() => {
    const getItems = async () => {
      const i = await db
        .collection('items')
        .where('id', 'in', profile.items)
        .get()
        .then((snapshot) => {
          const res = snapshot.docs.map((doc) => doc.data());
          return res as TItem[];
        });
      setItems(i);
    };
    if (email && profile.items) {
      getItems();
    }
  }, [profile]);

  return items;
}

export function useAddress(userId?: string): TAddress {
  const [address, setAddress] = useState<TAddress>({} as TAddress);
  useEffect(() => {
    const getAddress = async () => {
      const a = await db
        .collection('addresses')
        .doc(userId)
        .get()
        .then((snapshot) => {
          const res = snapshot.data();
          return res as TAddress;
        });
      setAddress(a);
    };
    if (userId) {
      getAddress();
    }
  }, []);
  return address;
}
