import React, { useEffect, useState, useContext } from 'react';
import {
  Center,
  Stack,
  Button,
  Heading,
  Box,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { db, FirebaseContext } from '../context/FirebaseProvider';
import { useAddress } from '../hooks/useFirestore';
import { WalletContext } from '../context/WalletProvider';
import { TMetadata } from '../types/Metadata';

function TokenTransfer(props: { tokenId: number }): JSX.Element {
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const { transferToken, isConnected, getTokenURI, address } =
    useContext(WalletContext);
  const [metadata, setMetadata] = useState<TMetadata>();
  const { user } = useContext(FirebaseContext);
  const shippingAddress = useAddress(user?.uid);
  const isSetAddress = shippingAddress !== undefined;

  useEffect(() => {
    const getToken = async () => {
      const uri = await getTokenURI(props.tokenId);
      const data = await fetch(uri)
        .then((d) => d.json())
        .catch((error) => console.error(error));
      setMetadata(data as TMetadata);
    };
    getToken();
  }, []);

  const onClick = async () => {
    const tx = await transferToken(props.tokenId);
    if (tx !== undefined) {
      const redeem = {
        userId: user?.uid,
        email: user?.email,
        txHash: tx.hash,
        tokenId: props.tokenId,
        name: metadata?.name,
        description: metadata?.description,
        shippingAddress,
        walletAddress: address,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      db.collection('redeemRequests').doc().set(redeem);
      setIsConfirmed(true);
    }
  };
  return isConfirmed ? (
    <Center pt={4}>
      <Box borderRadius="10px" bg="green.100">
        <Text p="30px" fontSize="lg" color="green.600">
          NFTとアイテムの交換申請が完了しました
        </Text>
      </Box>
    </Center>
  ) : (
    <Center>
      <Stack
        direction="column"
        spacing="10px"
        bgColor="white"
        borderRadius="20px"
        p="30px"
        align="center"
      >
        <Heading size="md" pb={4}>
          2. 交換するNFTの情報を確認して、返送してください
        </Heading>
        <Text color="red.400">
          必ず行使期間をご確認ください。行使期間外の場合対応できない場合があります。
        </Text>
        <Box>
          <Link
            isExternal
            color="blue.400"
            href={`https://opensea.io/${address}`}
          >
            所有しているNFTを見る
          </Link>
        </Box>
        <Box>
          <Link
            isExternal
            color="blue.400"
            href="https://lp.0xdali.io/guide#exchange"
          >
            TokenIDの探し方
          </Link>
        </Box>
        <Box maxW="md" maxH="md">
          <Image
            boxSize="md"
            borderRadius="20px"
            objectFit="contain"
            src={metadata?.image}
          />
        </Box>
        <Box p={2} maxW="md">
          <Heading as="h1" size="md">
            {metadata?.name}
          </Heading>
          <Box pt={2} overflow="auto" textOverflow="ellipsis">
            {metadata?.description}
          </Box>
        </Box>

        {!isSetAddress && <Text color="red.400">住所を設定してください</Text>}
        <Button
          p={4}
          mb={4}
          borderRadius="20px"
          colorScheme="blackButton"
          w="180px"
          type="submit"
          onClick={onClick}
          isDisabled={!isConnected || !isSetAddress}
        >
          NFTを返送する
        </Button>
      </Stack>
    </Center>
  );
}

export default TokenTransfer;
