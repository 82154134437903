import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
/* eslint-disable import/no-extraneous-dependencies */ //
// https://stackoverflow.com/questions/67554921/vuejs-and-firebase-import-firebase-package-the-correct-way
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
/* eslint-enable import/no-extraneous-dependencies */ //

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = firebase.initializeApp(config);

export const db = app.firestore();

type FirebaseObj = {
  user?: firebase.User | null;
  isLoading: boolean;
  isAuthed: boolean;
  login(email: string, password: string): Promise<void>;
  sendLoginLink(email: string): Promise<void>;
  loginWithLink(): Promise<void>;
  logout(): Promise<void>;
};
export const FirebaseContext = createContext<FirebaseObj>({} as FirebaseObj);

// Auth周りのProvider
export const FirebaseProvider: React.FC = ({ children }) => {
  FirebaseProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const [user, setUser] = useState<firebase.User | null | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthed, setIsAuthed] = useState<boolean>(false);

  const login = async (email: string, password: string) => {
    await app
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        setIsAuthed(true);
      });
  };
  // Mail Linkでを送信
  const sendLoginLink = async (email: string) => {
    const settings = {
      url: `${window.location.href}`,
      handleCodeInApp: true,
    };
    await app
      .auth()
      .sendSignInLinkToEmail(email, settings)
      .then(() => window.localStorage.setItem('emailForSignIn', email));
  };
  // Mail Linkでログイン
  const loginWithLink = async () => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (
        firebase.auth().isSignInWithEmailLink(window.location.href) &&
        email
      ) {
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
            setIsAuthed(true);
          });
      }
    }
  };

  const logout = async () => {
    await app
      .auth()
      .signOut()
      .then(() => setIsAuthed(false));
  };

  useEffect(() => {
    app.auth().onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      setIsLoading(false);
    });
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        user,
        isLoading,
        isAuthed,
        login,
        sendLoginLink,
        loginWithLink,
        logout,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
