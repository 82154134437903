import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import AddressModal from './AddressModal';

function AddressButton(): JSX.Element {
  // Modal用
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Text p={4} mb={4} color="blue.400" onClick={onOpen}>
        配送先を編集する
      </Text>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>配送先を編集する</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddressModal />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddressButton;
