import React from 'react';
import {
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Center,
} from '@chakra-ui/react';
import Layout from '../components/Layout';
import ItemCards from '../components/ItemCards';
import RedeemCard from '../components/RedeemCard';

function Home(): JSX.Element {
  return (
    <Layout>
      <Tabs variant="soft-rounded">
        <Center>
          <TabList>
            <Tab>NFTを受け取る</Tab>
            <Tab>商品を受け取る</Tab>
          </TabList>
        </Center>
        <TabPanels>
          <TabPanel>
            <ItemCards />
          </TabPanel>
          <TabPanel>
            <RedeemCard />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  );
}

export default Home;
