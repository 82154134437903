import React, { useContext } from 'react';
import { Box, Stack, Text, Heading, Center } from '@chakra-ui/react';
import AddressButton from './AddressButton';
import { FirebaseContext } from '../context/FirebaseProvider';
import { useAddress } from '../hooks/useFirestore';

function AddressCard(): JSX.Element {
  const { user } = useContext(FirebaseContext);
  const address = useAddress(user?.uid);
  return (
    <Center>
      <Stack
        direction="column"
        spacing="30px"
        bgColor="white"
        borderRadius="20px"
        align="center"
      >
        <Box maxW="md" maxH="md">
          {address ? (
            <Box pb={4}>
              <Heading size="md" pb={4}>
                1. 配送先は以下で承っております。
              </Heading>
              <Text>氏名：{address.name}</Text>
              <Text>電話番号：{address.phone}</Text>
              <Text>郵便番号：{address.zip_code}</Text>
              <Text>都道府県：{address.state}</Text>
              <Text>市区町村：{address.city}</Text>
              <Text>番地：{address.line1}</Text>
              <Text>建物名・号室：{address.line2}</Text>
              <Text>備考：{address.memo}</Text>
            </Box>
          ) : (
            <Heading size="md" pb={4}>
              配送先が設定されていません。配送先住所を設定してください。
            </Heading>
          )}
        </Box>
        <AddressButton />
      </Stack>
    </Center>
  );
}

export default AddressCard;
