import React from 'react';
import Layout from '../components/Layout';
import LoginForm from '../components/LoginForm';

function Home(): JSX.Element {
  return (
    <Layout>
      <LoginForm />
    </Layout>
  );
}

export default Home;
