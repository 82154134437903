import React from 'react';
import { Link, Text, Box, Stack, Flex, Center } from '@chakra-ui/react';

function Footer(): JSX.Element {
  return (
    <Box pt="2vh" bottom={0} flexShrink={0} bgColor="black">
      <Flex minH="2vh" alignItems="top" justify="center">
        <Stack direction={['column', 'column', 'row']} px={4} spacing={4}>
          <Link
            color="white"
            isExternal
            href="https://pacific-battery-ac7.notion.site/Dali-f91a3bf4d886435e8c5872600d78de3f"
          >
            利用規約
          </Link>
          <Link color="white" isExternal href="https://lp.0xdali.io/sct">
            特商法に基づく表記
          </Link>
          <Link color="white" isExternal href="https://lp.0xdali.io/policy">
            プライバシーポリシー
          </Link>
          <Link color="white" isExternal href="https://lp.0xdali.io/contact">
            お問い合わせ
          </Link>
        </Stack>
      </Flex>
      <Center>
        <Text pt={2} pb={5} color="white">
          Copyright ©︎ Dali All Rights Reserved.
        </Text>
      </Center>
    </Box>
  );
}

export default Footer;
