import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Flex,
  Center,
  Button,
  Image,
  Box,
  IconButton,
  Menu,
  MenuList,
  MenuButton,
  HStack,
  Text,
  Link,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import MetamaskButton from './MetamaskButton';

import logo from '../images/logo.svg';
import { FirebaseContext } from '../context/FirebaseProvider';

function Header(): JSX.Element {
  const { user, logout } = useContext(FirebaseContext);
  const history = useHistory();
  return (
    <Flex align="center" justify="space-between" padding="1rem">
      <Center pl={4} onClick={() => history.push('/')}>
        <Center>
          <Image width="120px" src={logo} />
        </Center>
      </Center>
      {user && (
        <Box>
          {/* 大きいときはボタン */}
          <HStack spacing={4} display={{ base: 'none', md: 'block' }}>
            <Center>
              <Link
                pr={4}
                color="blue.400"
                isExternal
                href="https://lp.0xdali.io/guide"
              >
                使い方がわからない方はこちら
              </Link>
              <MetamaskButton />
              <Button
                colorScheme="blackButton"
                ml={2}
                onClick={async () => {
                  await logout().then(() => history.push('/login'));
                }}
              >
                ログアウト
              </Button>
            </Center>
          </HStack>
          {/* 小さいときはハンバーガー */}
          <Box px={4} display={{ base: 'flex', md: 'none' }}>
            <Menu>
              <MenuButton
                bgColor="white"
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon />}
              />
              <MenuList>
                <MetamaskButton />
                <Link
                  px={3}
                  color="blue.400"
                  isExternal
                  href="https://lp.0xdali.io/guide"
                >
                  使い方がわからない方はこちら
                </Link>
                <Flex
                  height="40px"
                  alignItems="center"
                  minW="-moz-fit-content"
                  _hover={{ background: 'var(--chakra-colors-gray-200)' }}
                  onClick={async () => {
                    await logout().then(() => history.push('/login'));
                  }}
                >
                  <Text pl={3}>ログアウト</Text>
                </Flex>
              </MenuList>
            </Menu>
          </Box>
        </Box>
      )}
    </Flex>
  );
}

export default Header;
