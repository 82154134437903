import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Center,
  Button,
  Text,
  Box,
} from '@chakra-ui/react';
import { db, FirebaseContext } from '../context/FirebaseProvider';
import { TAddress } from '../types/Address';

function AddressModal(): JSX.Element {
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const { user } = useContext(FirebaseContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TAddress>();
  const history = useHistory();

  const onSubmit = handleSubmit(async (values: TAddress) => {
    await db.collection('addresses').doc(user?.uid).set(values);
    setIsConfirmed(true);
    history.go(0);
  });

  return isConfirmed ? (
    <>
      <div className="ResultTitle" role="alert">
        設定が完了しました。
      </div>
    </>
  ) : (
    <>
      <form onSubmit={onSubmit}>
        <Box p={4}>
          <Text fontWeight="bold" pb={2}>
            商品を受け取る際、配送先となる住所を入力してください。
          </Text>
          <FormControl id="name" isInvalid={!!errors.name} py={2}>
            <FormLabel>氏名</FormLabel>
            <Input type="name" {...register('name', { required: true })} />
            <FormErrorMessage>氏名を入力してください</FormErrorMessage>
          </FormControl>

          <FormControl id="phone" isInvalid={!!errors.phone} py={2}>
            <FormLabel>電話番号</FormLabel>
            <Input type="phone" {...register('phone', { required: true })} />
            <FormErrorMessage>電話番号を入力してください</FormErrorMessage>
          </FormControl>

          <FormControl id="zip_code" isInvalid={!!errors.zip_code} py={2}>
            <FormLabel>郵便番号</FormLabel>
            <Input
              type="zip_code"
              {...register('zip_code', { required: true })}
            />
            <FormErrorMessage>郵便番号を入力してください</FormErrorMessage>
          </FormControl>

          <FormControl id="state" isInvalid={!!errors.state} py={2}>
            <FormLabel>都道府県</FormLabel>
            <Input type="state" {...register('state', { required: true })} />
            <FormErrorMessage>都道府県を入力してください</FormErrorMessage>
          </FormControl>

          <FormControl id="city" isInvalid={!!errors.city} py={2}>
            <FormLabel>市区町村</FormLabel>
            <Input type="city" {...register('city', { required: true })} />
            <FormErrorMessage>市区町村を入力してください</FormErrorMessage>
          </FormControl>

          <FormControl id="line1" isInvalid={!!errors.line1} py={2}>
            <FormLabel>番地</FormLabel>
            <Input type="line1" {...register('line1', { required: true })} />
            <FormErrorMessage>番地を入力してください</FormErrorMessage>
          </FormControl>

          <FormControl id="line2" py={2}>
            <FormLabel>建物名・号室</FormLabel>
            <Input type="line2" {...register('line2')} />
          </FormControl>

          <FormControl id="memo" py={2}>
            <FormLabel>備考</FormLabel>
            <Input type="memo" {...register('memo')} />
          </FormControl>
        </Box>

        <Center>
          <Button
            p={4}
            mb={4}
            borderRadius="20px"
            colorScheme="blackButton"
            w="180px"
            type="submit"
          >
            配送先を設定する
          </Button>
        </Center>
      </form>
    </>
  );
}

export default AddressModal;
