import React, { useState, useContext } from 'react';
import { VStack, Button, Text, Center, Image } from '@chakra-ui/react';
import { WalletContext } from '../context/WalletProvider';
import { db, FirebaseContext } from '../context/FirebaseProvider';
import { TItem } from '../types/Item';

function RecieveModal(props: TItem): JSX.Element {
  const { address } = useContext(WalletContext);
  const { user } = useContext(FirebaseContext);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const onClick = async () => {
    await db
      .collection('recieveRequests')
      .doc()
      .set({
        walletAddress: address,
        email: user?.email,
        userId: user?.uid,
        item: props,
      })
      .catch((error) => console.log(error));
    setIsConfirmed(true);
  };

  return isConfirmed ? (
    <Text p={4}>受け取り申請を受け付けました。</Text>
  ) : (
    <VStack p={4} align="center">
      <Center>
        <Image src={props.image} />
      </Center>
      <Text fontWeight="bold" pb={4}>
        {props.name}
      </Text>
      <Text>NFTを受け取るウォレットアドレス</Text>
      <Text pb={4}>{address}</Text>
      <Button onClick={onClick} colorScheme="blackButton">
        受け取り申請をする
      </Button>
    </VStack>
  );
}

export default RecieveModal;
