import React, { useContext } from 'react';
import { Center, SimpleGrid } from '@chakra-ui/react';

import { FirebaseContext } from '../context/FirebaseProvider';
import { useItems } from '../hooks/useFirestore';
import ItemCard from './ItemCard';

function ItemCards(): JSX.Element {
  const { user } = useContext(FirebaseContext);
  const items = useItems(user?.email || '');

  return (
    <Center>
      <SimpleGrid my="5vh" py={4} columns={1} gap={10}>
        {items.map((item) => (
          <Center key={item.id}>
            <ItemCard {...item} />
          </Center>
        ))}
      </SimpleGrid>
    </Center>
  );
}

export default ItemCards;
