import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FormControl,
  FormLabel,
  Box,
  Center,
  Input,
  Button,
  Text,
} from '@chakra-ui/react';

import { FirebaseContext } from '../context/FirebaseProvider';

function LoginForm(): JSX.Element {
  const { isAuthed, sendLoginLink, loginWithLink } =
    useContext(FirebaseContext);
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const [email, setEmail] = useState<string>(query.get('email') || '');
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    loginWithLink();
    if (isAuthed) {
      history.push('/');
    }
  }, [isAuthed]);

  return isConfirmed ? (
    <Center>認証メールを送信しました。</Center>
  ) : (
    <Center>
      <Box mt={6} p={6} w="md" overflow="hidden">
        <FormControl id="email" my={3}>
          <FormLabel>メールアドレス</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
            }}
          />
        </FormControl>
        <Text>{error}</Text>
        <Center>
          <Button
            my={3}
            onClick={async () => {
              await sendLoginLink(email)
                .then(() => {
                  setIsConfirmed(true);
                })
                .catch((e) => {
                  setError(e.message);
                });
            }}
          >
            認証メールを送信
          </Button>
        </Center>
      </Box>
    </Center>
  );
}

export default LoginForm;
