import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { WalletContext } from '../context/WalletProvider';
import RecieveModal from './RecieveModal';
import { TItem } from '../types/Item';

function RecieveButton(props: TItem): JSX.Element {
  const { isConnected } = useContext(WalletContext);
  // Modal用
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        colorScheme="blackButton"
        isDisabled={!isConnected || props.isRequested}
        onClick={onOpen}
      >
        NFTを受け取る
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>NFTを受け取る</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RecieveModal {...props} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default RecieveButton;
