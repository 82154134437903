import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Fortmatic from 'fortmatic';
import { Magic } from 'magic-sdk';
import { ethers } from 'ethers';
import Torus from '@toruslabs/torus-embed';
import * as artifact from '../contracts/super721V2.json';
import { THexObj } from '../types/Transaction';

// eslint-disable-next-line
declare let window: any;

type WalletType = 'Metamask' | 'Fortmatic' | 'Magic' | 'Torus' | undefined;

type Wallet = {
  address?: string;
  isConnected?: boolean;
  walletType: WalletType;
  connectMetamask(): Promise<void>;
  connectFortmatic(): Promise<void>;
  connectTorus(): Promise<void>;
  connectMagic(email: string): Promise<void>;
  provider?: ethers.providers.Web3Provider;
  getAllTokens(): any;
  getTokenURI(tokenId: number): Promise<string>;
  transferToken(tokenId: number): Promise<ethers.Transaction>;
  getTotalSupply(): Promise<number>;
  getTokenOwner(tokenId: number): Promise<string>;
};

async function connectWalletMetamask() {
  const provider = await window.ethereum
    .request({
      method: 'eth_requestAccounts',
      params: [{ eth_accounts: {} }],
    })
    .then(() => new ethers.providers.Web3Provider(window.ethereum))
    .catch((error: Error) => {
      console.log(error);
    });
  // Set Polygon network
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x89',
        chainName: 'Matic',
        nativeCurrency: { name: 'Matic', symbol: 'MATIC', decimals: 18 },
        rpcUrls: ['https://rpc-mainnet.matic.network'],
        blockExplorerUrls: ['https://polygonscan.com/'],
      },
    ],
  });
  return provider;
}

async function connectWalletFortmatic() {
  //   const options = {
  //     rpcUrl: 'https://rpc-mainnet.matic.network',
  //     chainId: 80001,
  //   };
  //   const API_KEY = process.env.REACT_APP_FORTMATIC_API_KEY_DEV || '';
  const API_KEY = process.env.REACT_APP_FORTMATIC_API_KEY || '';
  const fm = new Fortmatic(API_KEY);
  // 公式でas any使え https://docs.fortmatic.com/migrating-from-v1.x#typescript-definitions
  // eslint-disable-next-line
  return new ethers.providers.Web3Provider(fm.getProvider() as any);
}

async function connectWalletMagic(email: string) {
  const API_KEY = process.env.REACT_APP_MAGIC_API_KEY || '';
  const magic = new Magic(API_KEY);
  await magic.auth.loginWithMagicLink({
    email,
    redirectURI: new URL('/callback', window.location.origin).href,
  });
  // 公式でas any使え https://docs.fortmatic.com/migrating-from-v1.x#typescript-definitions
  // eslint-disable-next-line
  return new ethers.providers.Web3Provider(magic.rpcProvider as any);
}

async function connectWalletTorus() {
  const torus = new Torus();
  await torus.init();
  await torus.setProvider({
    host: 'https://rpc-mainnet.matic.network',
    chainId: 137,
    networkName: 'Matic Mainnet',
  });
  await torus.login();
  return new ethers.providers.Web3Provider(torus.provider as any);
}

export const WalletContext = createContext<Wallet>({} as Wallet);

export const WalletProvider: React.FC = ({ children }) => {
  WalletProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
  const [address, setAddress] = useState<string>();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [walletType, setWalletType] = useState<WalletType>('Metamask');
  const [provider, setProvider] = useState<ethers.providers.Web3Provider>();
  const [contract, setContract] = useState<ethers.Contract>();
  const [totalSupply, setTotalSupply] = useState<number>();

  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS || '';

  const getAddress = async (p: ethers.providers.Web3Provider) => {
    const a = await p.getSigner().getAddress();
    setAddress(a);
    setIsConnected(true);
    return a;
  };

  // NFTのOwnerをとってくる
  const getTokenOwner = async (tokenId: number) => {
    if (contract) {
      const res = await contract
        .ownerOf(tokenId)
        .then((tx: ethers.Transaction) => tx);
      return res;
    }
    return '';
  };

  // NFTの合計量をとってくる
  const getTotalSupply = async () => {
    if (contract) {
      const total = await contract
        .totalSupply()
        .then((tx: THexObj) => parseInt(tx._hex, 16));
      setTotalSupply(total);
      return total;
    }
    return 0;
  };

  // TODO: すべてのNFTの情報をとってくる
  const getAllTokens = async () => {
    const tokens = [];
    if (contract && totalSupply) {
      //       const tokens = await Promise.all(
      //         [...Array(maxTokenIndex).keys()].map(async (x) => {
      //           const owner = await getTokenOwner(x + 1);
      //           return { tokenId: x + 1, owner };
      //         })
      //       );
      //  おそい！
      for (let i = 1; i <= totalSupply; i += 1) {
        console.log(i);
        // eslint-disable-next-line
        const owner = await getTokenOwner(i);
        tokens.push({ tokenId: i, owner });
      }
    }
    return tokens;
  };

  // NFTのMetadataURIをとってくる
  const getTokenURI = async (tokenId: number) => {
    if (contract) {
      const uri = await contract
        .tokenURI(tokenId)
        .then((tx: ethers.Transaction) => tx)
        .catch((error: Error) => {
          console.error(error);
        });
      return uri;
    }
    return '';
  };

  // NFTを送信する
  const transferToken = async (tokenId: number) => {
    if (contract && address) {
      const toAddress = process.env.REACT_APP_TO_ADDRESS || '';
      if (toAddress !== '') {
        const transfer = await contract
          .transferFrom(address, toAddress, tokenId)
          .then((tx: ethers.Transaction) => tx)
          .catch((error: Error) => {
            console.error(error);
            return undefined;
          });
        return transfer;
      }
    }
    return undefined;
  };

  const getContract = async (p: ethers.providers.Web3Provider) => {
    const c = new ethers.Contract(contractAddress, artifact.abi, p.getSigner());
    setContract(c);
    return c;
  };

  const connectMetamask = async () => {
    await connectWalletMetamask()
      .then(async (p) => {
        setProvider(p);
        await getAddress(p);
        await getContract(p);
        setWalletType('Metamask');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const connectFortmatic = async () => {
    await connectWalletFortmatic()
      .then(async (p) => {
        setProvider(p);
        await getAddress(p);
        await getContract(p);
        setWalletType('Fortmatic');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const connectMagic = async (email: string) => {
    await connectWalletMagic(email)
      .then(async (p) => {
        setProvider(p);
        await getAddress(p);
        setWalletType('Magic');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const connectTorus = async () => {
    await connectWalletTorus()
      .then(async (p) => {
        setProvider(p);
        await getContract(p);
        await getAddress(p);
        setWalletType('Torus');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    switch (walletType) {
      case 'Metamask':
        connectMetamask();
        break;
      case 'Fortmatic':
        connectFortmatic();
        break;
      default:
        break;
    }
    if (provider) {
      getAddress(provider);
    }
  }, [walletType, address, totalSupply]);

  return (
    <WalletContext.Provider
      value={{
        address,
        isConnected,
        walletType,
        connectMetamask,
        connectFortmatic,
        connectMagic,
        connectTorus,
        provider,
        getAllTokens,
        getTokenURI,
        transferToken,
        getTotalSupply,
        getTokenOwner,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
