import React, { useContext, useState } from 'react';
import { Button, Flex, Text, Spinner } from '@chakra-ui/react';
import { WalletContext } from '../context/WalletProvider';

function MetamaskButton(): JSX.Element {
  const { connectMetamask, address } = useContext(WalletContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      {/* 大きいときはボタン */}
      <Button
        colorScheme="blackButton"
        overflow="auto"
        textOverflow="ellipsis"
        display={{ base: 'none', md: 'block' }}
        onClick={async () => {
          setIsLoading(true);
          await connectMetamask();
          setIsLoading(false);
        }}
      >
        {address
          ? `${address.slice(0, 6)}...${address.slice(-6)}`
          : 'ウォレットと接続'}
      </Button>

      {/* 小さいときはメニュー */}
      <Flex
        display={{ base: 'flex', md: 'none' }}
        height="40px"
        alignItems="center"
        minW="-moz-fit-content"
        _hover={{ background: 'var(--chakra-colors-gray-200)' }}
        onClick={async () => {
          setIsLoading(true);
          await connectMetamask();
          setIsLoading(false);
        }}
      >
        <Text pl={3}>Walletと接続</Text>
      </Flex>
    </>
  );
}

export default MetamaskButton;
