import React from 'react';
import { Box, Center, Stack } from '@chakra-ui/react';
import AddressCard from './AddressCard';
import TokenOwnerCheckCard from './TokenOwnerCheckCard';

export type FormValues = {
  tokenId: number;
};

function RedeemCard(): JSX.Element {
  return (
    <Center>
      <Stack
        direction="column"
        spacing="30px"
        bgColor="white"
        borderRadius="20px"
        p="30px"
        align="center"
      >
        <Box w="md">
          <AddressCard />
          <TokenOwnerCheckCard />
        </Box>
      </Stack>
    </Center>
  );
}

export default RedeemCard;
