import React from 'react';
import { Box } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';

function Layout(props: { children: React.ReactNode }): JSX.Element {
  return (
    <>
      <Box minH="100vh" position="relative" pb="15px">
        <Header />
        {props.children}
      </Box>
      <Footer />
    </>
  );
}

export default Layout;
