import { extendTheme } from '@chakra-ui/react';
// 2. Call `extendTheme` and pass your custom values
const Theme = extendTheme({
  styles: {
    global: {
      body: {
        height: '100%',
        background: '#f4f4f4',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '20px',
      },
    },
  },
  colors: {
    blackButton: {
      50: '#000000',
      // bg
      500: '#171923',
      // hover
      600: '#4A5568',
    },
    ncomixButton: {
      50: '#000000',
      500: '#FC2A0D',
    },
  },
});

export default Theme;
